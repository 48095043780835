import React from "react";
import {
  Button,
  Container,
  CssBaseline,
  CircularProgress,
} from "@material-ui/core";
import { useAdminState } from "../../context/AdminContext";
import { login, getUser } from "../../services/auth";
import { navigate } from "gatsby";

const Login = () => {
  const { submitting } = useAdminState().login;

  const validateAndLogin = (e) => {
    e.preventDefault();
    getUser().then((user) => {
      if (user) {
        navigate("/");
      } else {
        login();
      }
    });
  };

  return (
    <Container component="main" maxWidth="lg" className="login-container">
      <CssBaseline />
      <div className="login-wrapper">
        <div className="welcome-section">
          <h1>Welcome PMO Dashboard</h1>
        </div>

        <div className="login-section">
          <div className="avatar">
            <svg
              id="Group_57908"
              data-name="Group 57908"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 146.409 45"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_38245"
                    data-name="Rectangle 38245"
                    width="146.409"
                    height="45"
                    fill="none"
                  />
                </clipPath>
              </defs>
              <g
                id="Group_57839"
                data-name="Group 57839"
                clipPath="url(#clip-path)"
              >
                <path
                  id="Path_101593"
                  data-name="Path 101593"
                  d="M10.89,21.933C4.478,21.933,0,17.423,0,10.966S4.478,0,10.89,0a10.46,10.46,0,0,1,7.637,3.231l.153.16a1.509,1.509,0,0,1-.065,2.149L17.252,6.8a1.517,1.517,0,0,1-2.111-.059l-.148-.154A5.526,5.526,0,0,0,10.915,4.9a5.692,5.692,0,0,0-5.96,6.062,5.692,5.692,0,0,0,5.96,6.062,6,6,0,0,0,4.237-1.719l.154-.152a1.514,1.514,0,0,1,2.124.009l1.325,1.325a1.511,1.511,0,0,1,0,2.133l-.154.155a10.817,10.817,0,0,1-7.711,3.153"
                  fill="#0071cd"
                />
                <path
                  id="Path_101594"
                  data-name="Path 101594"
                  d="M105.779,21.933h-1.566a1.691,1.691,0,0,1-1.689-1.689V1.881A1.883,1.883,0,0,1,104.405,0h7.535c4.645,0,7.766,3.06,7.766,7.614s-3.121,7.588-7.766,7.588h-4.473v5.042a1.69,1.69,0,0,1-1.688,1.689m1.688-11.6h4.423c1.88,0,2.874-.941,2.874-2.721,0-1.8-.993-2.747-2.874-2.747h-4.423Z"
                  transform="translate(-82.294)"
                  fill="#0071cd"
                />
                <path
                  id="Path_101595"
                  data-name="Path 101595"
                  d="M276.673,139.7A74.285,74.285,0,0,1,228.3,121.929a2.2,2.2,0,0,1-.19-3.189,2.413,2.413,0,0,1,3.315-.183,69.889,69.889,0,0,0,90.506,0,2.412,2.412,0,0,1,3.315.183,2.2,2.2,0,0,1-.19,3.189A74.285,74.285,0,0,1,276.673,139.7"
                  transform="translate(-182.618 -94.703)"
                  fill="#ffc01f"
                />
                <path
                  id="Path_101596"
                  data-name="Path 101596"
                  d="M230.293,21.933h-.142a2.408,2.408,0,0,1-2.2-1.436l-1.38-3.129a.034.034,0,0,0-.031-.021h-9.194a.034.034,0,0,0-.031.02l-1.293,2.891a2.833,2.833,0,0,1-2.583,1.674,2.1,2.1,0,0,1-1.912-2.953l7.764-17.308A2.834,2.834,0,0,1,221.869,0h1.051a1.433,1.433,0,0,1,1.306.846L232.3,18.837a2.2,2.2,0,0,1-2,3.1m-10.941-9.147h5.18l-2.59-5.831Z"
                  transform="translate(-169.638 0)"
                  fill="#0071cd"
                />
                <path
                  id="Path_101597"
                  data-name="Path 101597"
                  d="M653.751,21.933h-.141a2.408,2.408,0,0,1-2.2-1.436l-1.381-3.129a.034.034,0,0,0-.031-.021H640.8a.034.034,0,0,0-.031.02l-1.293,2.891a2.833,2.833,0,0,1-2.583,1.674,2.1,2.1,0,0,1-1.912-2.953l7.764-17.308A2.834,2.834,0,0,1,645.328,0h1.05a1.433,1.433,0,0,1,1.306.846l8.071,17.992a2.2,2.2,0,0,1-2,3.1m-10.941-9.147h5.18L645.4,6.955Z"
                  transform="translate(-509.54 0)"
                  fill="#0071cd"
                />
                <path
                  id="Path_101598"
                  data-name="Path 101598"
                  d="M554.509,21.933h-2.649a2.99,2.99,0,0,1-2.2-.964l-.032-.038-4.725-6.065H542v5.778a1.291,1.291,0,0,1-1.29,1.29h-2.062a1.292,1.292,0,0,1-1.29-1.29V1.819A1.821,1.821,0,0,1,539.174,0h8.014c4.655,0,7.783,2.987,7.783,7.433a7.054,7.054,0,0,1-4.458,6.831l4.808,5.972a1.043,1.043,0,0,1-.813,1.7M542,10.277h5.219c2.008,0,3.115-1.01,3.115-2.845s-1.106-2.845-3.115-2.845H542Z"
                  transform="translate(-431.325)"
                  fill="#0071cd"
                />
                <path
                  id="Path_101599"
                  data-name="Path 101599"
                  d="M450.234,21.933h-1.952a1.347,1.347,0,0,1-1.345-1.345v-16h-5.856a.871.871,0,0,1-.713-1.371l1.544-2.2A2.39,2.39,0,0,1,443.867,0h13.724a.759.759,0,0,1,.759.758v.751a3.082,3.082,0,0,1-3.079,3.079h-3.692v16a1.346,1.346,0,0,1-1.345,1.345"
                  transform="translate(-353.348)"
                  fill="#0071cd"
                />
                <path
                  id="Path_101600"
                  data-name="Path 101600"
                  d="M326.017,21.933a2.272,2.272,0,0,1-1.606-3.878l7.089-7.089L324.41,3.878A2.272,2.272,0,1,1,327.623.665l8.7,8.7a2.272,2.272,0,0,1,0,3.213l-8.7,8.695a2.264,2.264,0,0,1-1.606.665"
                  transform="translate(-259.864 0)"
                  fill="#ffc01f"
                />
                <path
                  id="Path_101601"
                  data-name="Path 101601"
                  d="M391.906,8.347l4.487-4.487a2.271,2.271,0,0,0-.03-3.242A2.321,2.321,0,0,0,393.127.7l-5.4,5.4,2.251,2.251a1.359,1.359,0,0,0,1.922,0"
                  transform="translate(-311.225 0)"
                  fill="#0071cd"
                />
                <path
                  id="Path_101602"
                  data-name="Path 101602"
                  d="M391.982,67.143a1.359,1.359,0,0,0-1.922,0l-2.252,2.251,5.448,5.448a2.272,2.272,0,0,0,3.324-.119,2.318,2.318,0,0,0-.161-3.143Z"
                  transform="translate(-311.286 -53.575)"
                  fill="#0071cd"
                />
              </g>
            </svg>
          </div>
          <form onSubmit={validateAndLogin} className="form">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="submit-bt"
              color="primary"
              disabled={submitting}
            >
              {submitting ? <CircularProgress color="secondary" /> : "Login"}
            </Button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default Login;
